import React, { forwardRef } from 'react'
import cx from 'classnames'
import { RouterLink } from 'router'


export type HrefProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  className?: string
  href?: string
  to?: string
  toTab?: string
  mailto?: string
  message?: string
  ariaLabel?: string
  block?: boolean
  inlineBlock?: boolean
  dataTestId?: string
}

const Href = forwardRef<HTMLAnchorElement, HrefProps>((props, ref) => {
  const { children, className, href, to, toTab, mailto, message, ariaLabel, block, inlineBlock, dataTestId, ...rest } = props

  const linkClassName = cx(className, {
    'block': block,
    'inline-block': inlineBlock,
  })

  const content = message ? (
    <span>{message}</span>
  ) : children

  if (href) {
    return (
      <a
        ref={ref}
        className={linkClassName}
        href={href}
        rel="noopener noreferrer nofollow"
        aria-label={ariaLabel}
        data-testid={dataTestId}
        {...rest}
      >
        {content}
      </a>
    )
  }

  if (to) {
    return (
      <RouterLink
        ref={ref}
        className={linkClassName}
        to={to}
        aria-label={ariaLabel}
        data-testid={dataTestId}
        {...rest}
      >
        {content}
      </RouterLink>
    )
  }

  if (toTab) {
    return (
      <a
        ref={ref}
        className={linkClassName}
        href={toTab}
        target="_blank"
        rel="noopener noreferrer nofollow"
        aria-label={ariaLabel}
        data-testid={dataTestId}
        {...rest}
      >
        {content}
      </a>
    )
  }

  if (mailto) {
    return (
      <a
        ref={ref}
        className={linkClassName}
        href={`mailto:${mailto}`}
        aria-label={ariaLabel}
        data-testid={dataTestId}
        {...rest}
      >
        {content || mailto}
      </a>
    )
  }

  return (
    <a
      ref={ref}
      className={linkClassName}
      aria-label={ariaLabel}
      data-testid={dataTestId}
      {...rest}
    >
      {content}
    </a>
  )
})


export default React.memo(Href)
